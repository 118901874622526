import React, { Component } from 'react'
import { HashRouter as Router, Route , useParams } from 'react-router-dom'
// import 'bulma/css/bulma.css'
import './App.css'
import CaptureHome from './CaptureHome'
import CaptureScreen from './CaptureScreen'

class App extends Component {
  render() {
    return (
      <Router>
        <div className="full">
          <Route path="/" component={CaptureHome} />
          <Route path="#/Hello" component={CaptureScreen} />
        </div>
      </Router>
    )
  }
}

export default App

