import React, { useCallback,Fragment } from 'react';
//import { ScreenCapture } from 'react-screen-capture';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import logo from './logo.svg';
import './App.css';
import { MarkerArea } from 'markerjs';
import * as markerjs2 from 'markerjs2';
import { BASE_URL, API_BASE_URL } from './constants';
import axios from 'axios';
import ScreenCapture from './ScreenCapture'
class CaptureHome extends React.Component {

  // imgRef = React.createRef<HTMLImageElement>;
  constructor(props) {
    super(props);
    // this.sayHello = this.sayHello.bind(this);
    this.initialState = {
      actionSelected: "",
      actionClicked: "",
      width: "auto",
      display: false,
      height: "auto",
      set_width:"auto",
      set_height: "auto",
      hiddenActionForm: "hiddenActionForm",
      previousScreenState: '',
      screenCapture: '',
      src: null,
      isCropEnabled: false,
      crop: {
        unit: '%' 
      },
      openPopup: false,
      isButtonDisabled: false,
      isCompleteScr:false,
      fullscreen_enable:"modal-dialog modal-lg modal-dialog-centered browser-extension-modal"
    };
    this.state = this.initialState;
    this.resizeShow = this.resizeShow.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleCompleteScreencapture = this.handleCompleteScreencapture.bind(this);
    this.saveScreenshot = this.saveScreenshot.bind(this); 
  }

  

  imgRef = React.createRef();
  ref = React.createRef();
  completeScrn = React.createRef();

  showMarkerArea() {
    if (this.imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(this.imgRef.current);
      // markerArea.uiStyleSettings.toolbarStyleColorsClassName = 'bg-gray-50';
      // markerArea.uiStyleSettings.toolbarButtonStyleColorsClassName =
      //   'bg-gradient-to-t from-gray-50 to-gray-50 hover:from-gray-50 hover:to-pink-50 fill-current text-pink-300';
      // markerArea.uiStyleSettings.toolbarActiveButtonStyleColorsClassName =
      //   'bg-gradient-to-t from-pink-100 via-gray-50 to-gray-50 fill-current text-pink-400';
      // markerArea.uiStyleSettings.toolbarOverflowBlockStyleColorsClassName = "bg-gray-50";

      // markerArea.uiStyleSettings.toolboxColor = '#F472B6';
      // markerArea.uiStyleSettings.toolboxAccentColor = '#BE185D';
      // markerArea.uiStyleSettings.toolboxStyleColorsClassName = 'bg-gray-50';
      // markerArea.uiStyleSettings.toolboxButtonRowStyleColorsClassName = 'bg-gray-50';
      // markerArea.uiStyleSettings.toolboxPanelRowStyleColorsClassName =
      //   'bg-pink-100 bg-opacity-90 fill-current text-pink-400';
      // markerArea.uiStyleSettings.toolboxButtonStyleColorsClassName =
      //   'bg-gradient-to-t from-gray-50 to-gray-50 hover:from-gray-50 hover:to-pink-50 fill-current text-pink-300';
      // markerArea.uiStyleSettings.toolboxActiveButtonStyleColorsClassName =
      //   'bg-gradient-to-b from-pink-100 to-gray-50 fill-current text-pink-400';
      markerArea.renderImageType = 'image/png';     
      markerArea.settings.displayMode = 'popup';
    var marker = markerArea;
    let self = this;
      markerArea.addRenderEventListener((dataUrl, state) => {
        if (this.imgRef.current) {
          // this.imgRef.current.src = dataUrl;
          //if(marker.close()){
            marker.settings.displayMode = 'inline';
            marker.close();
            this.setState({
              screenCapture: dataUrl
            },()=>{
              setTimeout(self.setState({
                          display: true,
                          actionSelected: ''
                        })
                        ,5000);
            });
              
          //}
          
        }
      });
      // launch marker.js
      markerArea.show();
    }
  }


  handleScreenCapture = screenCapture => {
    this.setState({
      screenCapture,
      display: true
    });
  };

  handleCompleteScreencapture(){
    
    const canIRun  = navigator.mediaDevices.getDisplayMedia ;
    let self = this;

    const takeScreenShot = async () => {
      const stream = await navigator.mediaDevices.getDisplayMedia({
                           video: { mediaSource: 'screen' },
                      });
        // get correct video track
        const track = stream.getVideoTracks()[0]
        // init Image Capture and not Video stream
        const imageCapture = new ImageCapture(track)
        // take first frame only
        const bitmap = await imageCapture.grabFrame()
        // destory video track to prevent more recording / mem leak
        track.stop()

        const canvas = document.getElementById('fake') 
        // this could be a document.createElement('canvas') if you want
        // draw weird image type to canvas so we can get a useful image
        canvas.width = bitmap.width
        canvas.height = bitmap.height
        const context = canvas.getContext('2d')
        context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
        const image = canvas.toDataURL();
        self.setState({
          screenCapture: image,
          display: true
        });

        
      }     
      
      canIRun && takeScreenShot();
  }


  resizeShow() {
    this.setState({ actionClicked:'resize'});
    this.setState({ resizeDiv: !this.state.resizeDiv, hiddenActionForm: ""});

    // alert(this.state.actionClicked)
  }
  cancelResizePic = () => {
    this.setState({
      hiddenActionForm: "hiddenActionForm",
    });
  }
  resizePic = () => {
    // alert('ji');

    this.setState({
      updwidth: this.state.width + "px",
      updheight: this.state.height + "px",
      resizeDiv: !this.state.resizeDiv,
      set_width: this.state.width,
      set_height: this.state.height,
    });
  }
  handleSave = () => {
    const screenCaptureSource = this.state.screenCapture;
    const downloadLink = document.createElement('a');
    const fileName = 'react-screen-capture.png';

    downloadLink.href = screenCaptureSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handleCrop() {
    this.setState({
      isCropEnabled: !this.state.isCropEnabled,
     'actionSelected': !this.state.isCropEnabled ? 'crop' : '',
     'hiddenActionForm': "hiddenActionForm",
     'actionClicked':"crop"
    }, () => {
      
      if (!this.state.isCropEnabled) {
        this.setState({
          previousScreenState: this.state.screenCapture,
          screenCapture: this.state.croppedImageUrl
        });
      }
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  onSizeChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      // alert('hi');
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  closePopup() {
    // this.setState(this.initialState);
    // window.open("about:blank", "_self");
    window.close();
  }

  componentDidMount(){
    axios.defaults.baseURL  = API_BASE_URL+'/v1'; 
    // setTimeout(() => {
    this.ref.current.click();
    // }, 5000);
    
    

    // alert(res);
  }


  checkKeyPress(key){
    // alert(key)
    // console.log(key)
    if(key=="c"){
      this.handleCompleteScreencapture();
    }
    // this.completeScrn.current.click();
  }

  fullScreenMode = () => {
    if(this.state.fullscreen_enable=="modal-dialog modal-lg modal-dialog-centered browser-extension-modal"){

      this.setState({
        fullscreen_enable:"modal-dialog modal-lg modal-dialog-centered browser-extension-modal fullscreen_enable"
      })
    }else{
      this.setState({
        fullscreen_enable:"modal-dialog modal-lg modal-dialog-centered browser-extension-modal"
      })
    }
  }
  printScreen = () => {
    var printContents = document.getElementById("print_this").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  saveScreenshot(){
    const {screenCapture} = this.state;
    console.log(this.state.screenCapture);
    var url="https://purpledevelopmentappservice.azurewebsites.net/Home/UploadScreenshot";
    axios.post(url, { 'screenshot': this.state.screenCapture, 'url':url }).then(function (response) {
      console.log('response');
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { crop, croppedImageUrl, src, screenCapture, openPopup, isCropEnabled, actionSelected } = this.state;
    console.log(screenCapture);

    const rotateImage = (degree) => {
      // alert('jiii');
      this.setState({
        'actionClicked': "rotate",
        'hiddenActionForm': "hiddenActionForm"
      })
      // alert(this.state.actionClicked);
      let img = new Image();
      img.src = document.getElementById("myimage").src;
      let canvas = document.createElement("canvas");
      // style={{background-color: this.state.display ? 'block' : 'none' }}
      img.onload = function () { 
        let ctx = canvas.getContext("2d");
        console.log(img.width);
        console.log(img.height);

        // canvas.width = img.width;
        // canvas.height = img.height;

        canvas.width = img.height;
        canvas.height = img.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        // ctx.rotate(Math.PI);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        let a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        changeRotation(a.href);
      };
    };

    const changeRotation = (rotation) => {
      this.setState({
        screenCapture: rotation,
      });
    };

    const annotation = () => {

      this.setState({
        'actionSelected': 'annotation',
        'hiddenActionForm': "hiddenActionForm",
        'display': false
      },()=>{
        this.showMarkerArea();
      });
       
    }
    return (
      <React.Fragment>

         
        <ScreenCapture onEndCapture={this.handleScreenCapture}>
          {({ onStartCapture }) => (

            <section>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 my-3" >
                    <div className="card" style={{display:'none'}}>
                      <div className="card-body">
                        <a id="btnCaptureMode" style={{display:'none'}} href="#" ref={this.completeScrn} onClick={this.handleCompleteScreencapture} className="btn btn-primary">Capture Screenshot</a>
                        <a id="btnCaptureMode" style={{display:'none'}} href="#" ref={this.ref} onClick={onStartCapture} className="btn btn-primary">Capture Screenshot</a>
                        <a id="btnScreenshotModal" style={{display:'none'}} href="#" data-bs-toggle="modal" data-bs-target="#CaptureImageModal" className="btn btn-primary">Edit Screenshot</a>     
                      </div>
                      <KeyboardEventHandler handleKeys={['all']} onKeyEvent={(key) => this.checkKeyPress(key)} />
                    </div>
                  </div>


                </div>
                <div id="Dragcanvas" className="SelectableCanvas"></div>
                <div className="cursor">
                  <div className="vt"></div>
                  <div className="hl"></div>
                </div>
              </div>
                        {actionSelected == 'annotation' && <div className="col-md-12">

                          <img
                            ref={this.imgRef}
                            className="img-fluids"
                            src={screenCapture}
                            width={this.state.set_width}
                            height={this.state.set_height}
                            style={{  opacity:  1 }}
                            onClick={() => this.showMarkerArea()}
                          />
                        </div>}

              {screenCapture  && <div className="modal fade show" id="CaptureImageModal" tabIndex="-1" aria-labelledby="CaptureImageModalLabel" role="dialog" aria-modal="true" style={{ display: this.state.display ? 'block' : 'none' }}>
                <div className={this.state.fullscreen_enable}>
                  <div className="modal-content position-relative">
                    <div className="SaveLoading">
                      <div className="d-flex h-100 align-items-center justify-content-center">
                        <div className="LoadingText">
                          <span className="iconify SaveLoadingIcon mr-2" data-icon="ei:spinner-2"></span>
                          <span className="SaveLoadingText">Saving To Tax Line</span>
                        </div>
                      </div>
                    </div>
                    <div className="modal-header position-relative">
                      <div className="ActionWrapper w-100">
                        <span className="d-flex align-items-center btnActionContainer">
                          <a className="btn btnActionGroup btn-Grey-Fill" data-action="SaveToTaxLine" onClick={this.saveScreenshot}><i class="fa fa-check" aria-hidden="true"></i> Save to Tax Line</a>
                          <a className="btn btnActionGroup btn-Grey-Fill" data-action="Cancel" onClick={this.closePopup}><i class="fa fa-times" aria-hidden="true"></i> Cancel</a>
                          <a className={this.state.actionClicked === "resize" ? "setActive btn btnActionGroup btn-White-Fill" : "btn btnActionGroup btn-White-Fill"} data-action="Resize" onClick={this.resizeShow}><i class="fa fa-arrows-alt" aria-hidden="true"></i> Resize</a>
                          <a className={this.state.actionClicked === "crop" ? "setActive btn btnActionGroup btn-White-Fill" : "btn btnActionGroup btn-White-Fill"} data-action="Crop" onClick={this.handleCrop}><i class="fa fa-crop" aria-hidden="true"></i> Crop</a>
                          <a className={this.state.actionClicked === "rotate" ? "setActive btn btnActionGroup btn-White-Fill" : "btn btnActionGroup btn-White-Fill"} data-action="Rotate" onClick={rotateImage}><i class="fa fa-repeat" aria-hidden="true"></i> Rotate</a>
                          <a className={this.state.actionClicked === "annotation" ? "setActive btn btnActionGroup btn-White-Fill" : "btn btnActionGroup btn-White-Fill"} data-action="Annotate" onClick={annotation}><i class="fa fa-object-group" aria-hidden="true"></i> Annotate</a>
                          <a className="btn btnActionGroup btn-White-Fill" onClick={this.printScreen} data-action="Print"><i class="fa fa-print" aria-hidden="true"></i> Print</a>
                          <a className="btn btnActionGroup btn-White-Fill" onClick={this.fullScreenMode} data-action="FullScreen"><i class="fa fa-object-ungroup" aria-hidden="true"></i> Full Screen</a>
                        </span>
                      </div>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                      <div id="ResizeContainer" className={this.state.hiddenActionForm}>
                        <div className="d-flex w-100 align-items-center justify-content-center ExtentionWrapper">
                          <div className="ExtensionContainer">
                            <span className="ExtensionLable" >Width</span>
                            <input type="text" className="ExtensionControl" name="width" onChange={this.onSizeChange} />
                          </div>
                          <div className="ExtensionContainer">
                            <span className="ExtensionLable">Height</span>
                            <input type="text" className="ExtensionControl" name="height" onChange={this.onSizeChange} />
                          </div>
                          <div className="ExtensionContainer">
                            <a className="btnExtensionAction" onClick={this.resizePic}><i class="fa fa-check" aria-hidden="true"></i></a>
                            <a className="btnExtensionAction" onClick={this.cancelResizePic}><i class="fa fa-times" aria-hidden="true"></i></a>
                          </div>
                        </div>
                      </div>
                      <div id="AnnotateContainer" className="hiddenActionForm">
                        <div className="d-flex w-100 align-items-center justify-content-center ExtentionWrapper">
                          <div className="ExtensionContainer">
                            <span className="ExtensionLable">Font Size</span>
                            <input type="text" className="ExtensionControl" />
                          </div>

                          <div className="CustomColorPickerRelativeWrapper">
                            <div className="CustomColorPickerAbsolutePositionedInner">
                              <div className="ExtensionContainer">
                                <span className="ExtensionLable">Font Color</span>
                                <input type="text" name="txtColorPickerTextBox" placeholder="#000000" id="txtColorPickerTextBox" className="ExtensionControl" />
                                <div className="CustomColorHolder CustomColorPicker"></div>
                              </div>
                              <div className="ColorPickerDisplayArea" id="ColorPickerDisplayArea" style={{ "display": "none" }}></div>
                            </div>
                          </div>

                          <div className="CustomColorPickerRelativeWrapper">
                            <div className="CustomColorPickerAbsolutePositionedInner">
                              <div className="ExtensionContainer">
                                <span className="ExtensionLable">Background Color</span>
                                <input type="text" name="txtColorPickerTextBox" placeholder="#000000" id="txtColorPickerTextBox" className="ExtensionControl" />
                                <div className="CustomColorHolder CustomColorPicker"></div>
                              </div>
                              <div className="ColorPickerDisplayArea" id="ColorPickerDisplayArea" style={{ "display": "none" }}></div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="row" id="print_out">
                      </div>
                      <div className="row" id="print_this">
                        {actionSelected == ''   && <div className="col-md-12">

                          <img                            
                            className="img-fluids"
                            src={screenCapture}
                            id="myimage"                          
                            width={this.state.set_width}
                            height={this.state.set_height}
                            
                          />
                        </div>}
                        
                        {actionSelected == 'crop' &&
                          <ReactCrop
                            src={screenCapture}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          />

                        }
                      </div>

                    </div>
                  </div>
                </div>
              </div>}

            </section>
          )}
        </ScreenCapture>
        <canvas id="fake" class="d-none"></canvas>
         

        <img alt="Crop" style={{ maxWidth: '100%',  height:'auto' }} id="capuredImage" src="https://coolbackgrounds.io/images/backgrounds/white/pure-white-background-85a2a7fd.jpg" />
      </React.Fragment>
    );
  }
}

export default CaptureHome;
